import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Close } from '@material-ui/icons';

import { PatientForm } from './PatientForm';
import { isEmpty } from '../../utils/ObjectUtils';
import Loading from '../../components/Common/Loading';
import { GET_PATIENT } from '../../graphql/queries';
import { UPDATE_PATIENT } from '../../graphql/mutations';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import useStyles from '../../components/Patient/styled/PatientMakeStyles';
import { ScrollableContainer } from '../Common/ScrollableContainer';


export default ({ patientId, handleCompleted }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital && state.hospital.uuid);
  const [serverError, setServerError] = useState(false);

  const { loading, data } = useQuery(
    GET_PATIENT,
    {
      variables: {
        uuid: patientId,
      },
    },
  );

  const [updatePatient, { loading: updating }] = useMutation(UPDATE_PATIENT, {
    onCompleted({ updatePatient: updatedPatient }) {
      handleCompleted(updatedPatient.uuid);
    },
    onError: (error) => {
      if (error && error.graphQLErrors && error.graphQLErrors.length) {
        setServerError(error.graphQLErrors[0].message);
      }
    },
  });

  const submitAction = (values, callback) => {
    let patient = { ...values, uuid: patientId };
    delete patient.hospitalUuid;

    updatePatient({ variables: { patient } })
      .then(callback());
  };

  const goBack = () => window.history.back();

  const backButton = { label: t('return'), icon: Close, onClick: goBack };

  const getApplicationPath = (patientData) => {
    if (isEmpty(patientData)) return null;
    return {
      patient: {
        name: `${patientData.name} ${patientData.surname}`,
        link: `/patient/${patientData.uuid}`,
      },
    };
  };

  const initialPatient = (loading || isEmpty(data)) ? {} : {
    customId: data.patient ? data.patient.customId || '' : '',
    name: data.patient ? data.patient.name || '' : '',
    surname: data.patient ? data.patient.surname || '' : '',
    email: data.patient ? data.patient.email || '' : '',
    birthDate: data.patient ? data.patient.birthDate || '' : '',
    hospitalUuid: hospitalUuid || '',
    identityNumber: data.patient ? data.patient.identityNumber || '' : '',
    nationality: data.patient ? data.patient.nationality || '' : '',
    insurance: data.patient ? data.patient.insurance || '' : '',
    insuranceNumber: data.patient ? data.patient.insuranceNumber || '' : '',
    phone: data.patient ? data.patient.phone || '' : '',
    address: data.patient ? data.patient.address || '' : '',
    gender: data.patient ? data.patient.gender || '' : '',
  };

  if (loading || isEmpty(data)) return <Loading />;

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="edit.patient" back={backButton} breadcrumbs={getApplicationPath(data.patient)} />
      </Navbar>
      <Box className={classes.container}>
        <ScrollableContainer>
          <Paper elevation={2} className={classes.wrapper}>
            <PatientForm
              patientId={patientId}
              initialValues={initialPatient}
              submitLabel={t('update')}
              submitAction={submitAction}
              serverError={serverError}
              setServerError={setServerError}
              submitting={updating}
              onClose={goBack}
            />
          </Paper>
        </ScrollableContainer>
      </Box>
    </>
  );
};
