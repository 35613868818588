import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../../components/MenuLayout';
import PatientEdit from '../../components/Patient/PatientEdit';

export default ({ patientId }) => {
  const onCompleted = (uuid) => {
    navigate(`/patient/${uuid}`);
  };

  return (
    <Layout>
      <PatientEdit patientId={patientId} handleCompleted={onCompleted} />
    </Layout>
  );
};
